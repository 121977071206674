import type { ProjectType } from '@/project/domain/entities/DetailedProject'
import { DetailedProject } from '@/project/domain/entities/DetailedProject'
import { FullyFinancedByContributor } from '@/project/domain/value-objects/FullyFinancedByContributor'
import { FinancedRatio } from '@/shared/domain/value-objects/FinancedRatio'
import type { FisHttpClient, FisProject } from '@/shared/infrastructure/gateways/Backoffice.gateway'
import type { SingleMetricData } from '@/project/domain/repositories/RemoteSensing.repository'
import type { BiomeProjectId, FisProjectId } from '@/shared/domain/Ids'
import type { CarbonSimulatorScenarioApiResponse } from '@/shared/infrastructure/gateways/CarbonSimulator.gateway'
import type { BiodiversitySimulationApiResults } from '@/shared/infrastructure/gateways/BiodiversitySimulator.gateway'
import type { Polygons } from '@/project/infrastructure/services/PolygonService'
import { TotalBiodiversityImpact } from '@/shared/domain/value-objects/TotalBiodiversityImpact'
import { FinancedBiodiversityImpact } from '@/shared/domain/value-objects/FinancedBiodiversityImpact'
import { TreeDensity } from '@/project/domain/value-objects/TreeDensity'
import { FinancedArea } from '@/shared/domain/value-objects/FinancedArea'
import { FinancedTrees } from '@/shared/domain/value-objects/FinancedTrees'
import { FinancedPercentage } from '@/shared/domain/value-objects/FinancedPercentage'
import { ResidualFinancedPercentage } from '@/shared/domain/value-objects/ResidualFinancedPercentage'
import DateOfNextRemoteMonitoring from '@/project/domain/value-objects/DateOfNextRemoteMonitoring'
import DateOfNextFieldMonitoring from '@/project/domain/value-objects/DateOfNextFieldMonitoring'
import MonitoringCalendar from '@/project/domain/entities/MonitoringCalendar'

import BiodiversitySimulation from '@/project/domain/entities/BiodiversitySimulation'
import ProjectScenario from '@/project/domain/value-objects/ProjectScenario'
import { CarbonSimulation, StartingYear } from '@/shared/domain/value-objects/CarbonSimulation'
import { CarbonSimulationDiscount } from '@/shared/domain/value-objects/CarbonSimulationDiscount'
import type {
  RemoteSensingIndexesResults,
  SpectralSpeciesResults,
} from '@/project/infrastructure/gateways/RemoteSensing.httpgateway'
import RemoteSensingIndexes, {
  type ProjectSnapshot,
  type SnapshotIndex,
  type SpectralSpeciesProperties,
} from '@/project/domain/entities/RemoteSensingIndexes'
import type { ReportsList } from '@/project/infrastructure/gateways/Backend.httpgateway'

const DEFAULT_TREE_COUNT = 1
const DEFAULT_COORDINATES = 0

export class DetailedProjectFactory {
  public static toDetailedProject(
    projectId: BiomeProjectId,
    fisProjectId: FisProjectId,
    client: FisHttpClient,
    fisProject: FisProject,
    rawCarbonSimulation: CarbonSimulatorScenarioApiResponse | undefined,
    biodiversitySimulationResults: BiodiversitySimulationApiResults[],
    polygons: Polygons,
    remoteSensingIndexesResults: RemoteSensingIndexesResults | null,
    livingBioMassCarbonMetrics: SingleMetricData[],
    reportings: ReportsList | null,
  ): DetailedProject {
    const totalTreeCount = fisProject.numberOfTrees ?? DEFAULT_TREE_COUNT

    const contributedTrees = client.attributions
      .filter((a) => a.idProject === projectId)
      .map((a) => a.nbStockApprouved)
      .sum()

    const financedRatio = new FinancedRatio(contributedTrees, totalTreeCount)
    const financedPercentage = new FinancedPercentage(financedRatio)

    const carbonSimulation = rawCarbonSimulation
      ? new CarbonSimulation(
          rawCarbonSimulation.results.totalCredits,
          StartingYear.fromPlantingSeason(fisProject.plantingSeason),
          new CarbonSimulationDiscount(),
          financedRatio,
        )
      : null

    const biodiversitySimulation =
      biodiversitySimulationResults.length === 0
        ? null
        : DetailedProjectFactory.constructBiodiversitySimulation(
            biodiversitySimulationResults.find((bs) => bs.projectId === fisProjectId),
          )
    const totalBiodiversityImpact = DetailedProjectFactory.constructTotalBiodiversityImpact(
      fisProject,
      biodiversitySimulationResults,
    )

    const monitoringCalendar =
      fisProject.plantingStartDate && fisProject.plantingEndDate
        ? new MonitoringCalendar(
            new Date(fisProject.plantingStartDate),
            new Date(fisProject.plantingEndDate),
            new DateOfNextRemoteMonitoring(
              new Date(fisProject.plantingStartDate),
              new Date(fisProject.plantingEndDate),
              DetailedProjectFactory.mapRecordTypeId(fisProject.recordTypeId),
            ),
            new DateOfNextFieldMonitoring(
              new Date(fisProject.plantingEndDate),
              DetailedProjectFactory.mapRecordTypeId(fisProject.recordTypeId),
            ),
            reportings,
          )
        : null

    return new DetailedProject(
      fisProject.idBiome,
      fisProject.id,
      DetailedProjectFactory.mapRecordTypeId(fisProject.recordTypeId),
      fisProject.plantingSeason,
      {
        city: fisProject.city,
        country: fisProject.country,
        continent: fisProject.continent,
        coordinates: {
          latitude: fisProject.centroidLatitude ?? DEFAULT_COORDINATES,
          longitude: fisProject.centroidLongitude ?? DEFAULT_COORDINATES,
        },
      },
      totalTreeCount,
      fisProject.area,
      {
        main: fisProject.mainTypology,
        secondaries: fisProject.secondaryTypology ? fisProject.secondaryTypology.split(';') : [],
      },
      polygons.map((p) => ({ coordinates: p.coordinates })),
      fisProject.species.map((s) => ({
        name: s.species,
        scientificName: s.scientificName,
        repartition: s.repartition,
      })),
      fisProject.implementorName,
      fisProject.speciesDestination ? fisProject.speciesDestination.split(';') : [],
      totalBiodiversityImpact,
      totalBiodiversityImpact
        ? new FinancedBiodiversityImpact(totalBiodiversityImpact, financedRatio)
        : null,
      new FullyFinancedByContributor(contributedTrees, totalTreeCount),
      new FinancedArea(fisProject.area, financedRatio),
      new FinancedTrees(totalTreeCount, financedRatio),
      new TreeDensity(totalTreeCount, fisProject.area),
      financedPercentage,
      new ResidualFinancedPercentage(financedPercentage),
      financedRatio,
      carbonSimulation,
      biodiversitySimulation,
      monitoringCalendar,
      remoteSensingIndexesResults
        ? new RemoteSensingIndexes(
            remoteSensingIndexesResults.id,
            remoteSensingIndexesResults.fisId,
            remoteSensingIndexesResults.snapshots.map((s) => {
              return <ProjectSnapshot>{
                year: s.year,
                id: s.id,
                endDate: s.endDate,
                startDate: s.startDate,
                projectId: s.projectId,
                url: s.url,
                ndvis: s.ndvis.map((n) => {
                  return <SnapshotIndex>{
                    id: n.id,
                    mean: n.mean,
                    maximum: n.maximum,
                    url: n.url,
                    minimum: n.minimum,
                    snapshotId: n.snapshotId,
                  }
                }),
                ndmis: s.ndmis.map((n) => {
                  return <SnapshotIndex>{
                    id: n.id,
                    mean: n.mean,
                    maximum: n.maximum,
                    url: n.url,
                    minimum: n.minimum,
                    snapshotId: n.snapshotId,
                  }
                }),
                isValidated: s.isValidated,
              }
            }),
            spectralSpeciesRawToDomain(remoteSensingIndexesResults.spectralSpecies),
          )
        : null,
      livingBioMassCarbonMetrics,
      reportings || null,
    )
  }

  private static mapRecordTypeId(recordTypeId: string): ProjectType {
    if (recordTypeId === '0123X000000a0LdQAI') return 'CARBON_PROJECT_PURCHASE_CREDIT'
    if (recordTypeId === '0123X000000a0LgQAI') return 'ASSISTED_NATURAL_REGENERATION_EU_US'
    if (recordTypeId === '0123X000001GKqPQAW') return 'CARBON_PROJECT_DEVELOPMENT'
    if (recordTypeId === '0123X000000a0LeQAI') return 'PLANTATION_PROJECT_EU_US'
    if (recordTypeId === '0123X000000a0LbQAI') return 'URBAN_FOREST_PROJECT'
    if (recordTypeId === '0123X000000a0LfQAI') return 'ECOSYSTEM_RESTORATION_PROJECT_OUTSIDE_EU_US'
    if (recordTypeId === '0123X000000a0LcQAI') return 'LABEL_BAS_CARBON_PROJECT'
    if (recordTypeId === '012AX0000004JRVYA2') return 'REGENERATIVE_AGRICULTURE'
    return 'UNKNOWN'
  }

  private static constructBiodiversitySimulation(
    biodiversitySimulationResults: BiodiversitySimulationApiResults | undefined,
  ): BiodiversitySimulation | null {
    if (biodiversitySimulationResults) {
      return new BiodiversitySimulation(
        biodiversitySimulationResults.projectId,
        biodiversitySimulationResults.results.bd1SpeciesLevelSupport,
        biodiversitySimulationResults.results.bd2StandStructuralAndBiologicalDiversity,
        biodiversitySimulationResults.results.bd3HabitatsAndResourcesMaintainedOrCreated,
        biodiversitySimulationResults.results.bd4WorksImpact,
        new ProjectScenario(
          biodiversitySimulationResults.results.bd1SpeciesLevelSupport,
          biodiversitySimulationResults.results.bd2StandStructuralAndBiologicalDiversity,
          biodiversitySimulationResults.results.bd3HabitatsAndResourcesMaintainedOrCreated,
          biodiversitySimulationResults.results.bd4WorksImpact,
        ),
      )
    }
    return null
  }

  private static constructTotalBiodiversityImpact(
    fisProject: FisProject,
    biodiversitySimulations: BiodiversitySimulationApiResults[],
  ): TotalBiodiversityImpact | null {
    if (biodiversitySimulations.length === 0) {
      return null
    }

    const biodiversitySimulationResults = biodiversitySimulations.find(
      (bs) => bs.projectId === fisProject.id,
    )
    if (!biodiversitySimulationResults) {
      return null
    } else {
      return new TotalBiodiversityImpact(
        fisProject.area,
        biodiversitySimulationResults.results.bd1SpeciesLevelSupport,
        biodiversitySimulationResults.results.bd2StandStructuralAndBiologicalDiversity,
        biodiversitySimulationResults.results.bd3HabitatsAndResourcesMaintainedOrCreated,
        biodiversitySimulationResults.results.bd4WorksImpact,
      )
    }
  }
}

function spectralSpeciesRawToDomain(
  spectralSpecies: SpectralSpeciesResults[],
): SpectralSpeciesProperties[] {
  const spectralSpeciesList: SpectralSpeciesProperties[] = []

  spectralSpecies.forEach((spectralSpecie: SpectralSpeciesResults) => {
    const step = findSpectralSpeciesUrl(spectralSpecie)
    if (step) {
      const date = spectralSpecie.snapshotId.split('-').pop()
      spectralSpeciesList.push({
        id: spectralSpecie.id,
        snapshotId: spectralSpecie.snapshotId,
        url: step.urls[0],
        optimalClasses: spectralSpecie.parameters.optimalPredictedClassesNumber,
        year: date ? date.slice(0, 4) : '',
      })
    }
  })

  return spectralSpeciesList
}

function findSpectralSpeciesUrl(spectralSpecie: SpectralSpeciesResults) {
  const step = spectralSpecie.steps.find((step) => step.step === 3 && step.status === 'COMPLETE')
  if (step) {
    return step
  }
}
