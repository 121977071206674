import DateOfNextRemoteMonitoring from '@/project/domain/value-objects/DateOfNextRemoteMonitoring'
import DateOfNextFieldMonitoring from '@/project/domain/value-objects/DateOfNextFieldMonitoring'
import type { ProjectType } from '@/project/domain/entities/DetailedProject'
import type { Report, ReportsList } from '@/project/infrastructure/gateways/Backend.httpgateway'

export type Monitoring = {
  occurrence: 'next' | 'latest'
  type: 'field' | 'remote'
  date: Date
}

export default class MonitoringCalendar {
  public constructor(
    public readonly plantingStartDate: Date,
    public readonly plantingEndDate: Date,
    private readonly dateOfNextRemoteMonitoring: DateOfNextRemoteMonitoring,
    private readonly dateOfNextFieldMonitoring: DateOfNextFieldMonitoring,
    private readonly projectsReports: ReportsList | null,
  ) {}

  public static hydrate(
    rawMonitoring: MonitoringCalendar,
    projectType: ProjectType,
    projectsReports: ReportsList | null,
  ): MonitoringCalendar {
    return new MonitoringCalendar(
      new Date(rawMonitoring.plantingStartDate),
      new Date(rawMonitoring.plantingEndDate),
      new DateOfNextRemoteMonitoring(
        new Date(rawMonitoring.plantingStartDate),
        new Date(rawMonitoring.plantingEndDate),
        projectType,
      ),
      new DateOfNextFieldMonitoring(new Date(rawMonitoring.plantingEndDate), projectType),
      projectsReports,
    )
  }

  public getRemoteMonitoring(): Monitoring {
    const lastReport = this.getLastReport('remote')
    const date = lastReport.monitoringDate
      ? new Date(lastReport.monitoringDate)
      : this.dateOfNextRemoteMonitoring.value
    return {
      occurrence: this.hasMonitoringHappened(date) ? 'latest' : 'next',
      type: 'remote',
      date,
    }
  }

  private getLastReport(reportType: string): Report {
    if (this.projectsReports) {
      let reportsList
      switch (reportType) {
        case 'field':
          reportsList = structuredClone(this.projectsReports.fieldsReports)
          return reportsList.length > 0 ? reportsList[reportsList.length - 1] : ({} as Report)
        case 'remote':
          reportsList = structuredClone(this.projectsReports.remoteReports)
          if (this.projectsReports.tropicalReports.length > 0) {
            reportsList = structuredClone(this.projectsReports.tropicalReports)
          }
          return reportsList.length > 0 ? reportsList[reportsList.length - 1] : ({} as Report)
      }
    }
    return {} as Report
  }

  public getFieldMonitoring(): Monitoring {
    const lastReport = this.getLastReport('field')
    const date = lastReport.monitoringDate
      ? new Date(lastReport.monitoringDate)
      : this.dateOfNextFieldMonitoring.value
    return {
      occurrence: this.hasMonitoringHappened(date) ? 'latest' : 'next',
      type: 'field',
      date,
    }
  }

  private hasMonitoringHappened(monitoring: Date): boolean {
    return monitoring < new Date(Date.now())
  }
}
