import type { DetailedProjectRepository } from '@/project/domain/repositories/DetailedProject.repository'
import { ViewModelContainer } from '@/shared/presentation/presenters/ViewModelContainer'
import type { SpectralSpeciesViewModel } from '@/project/presentation/view-models/SpectralSpecies.viewmodel'
import type { SpectralSpeciesProperties } from '@/project/domain/entities/RemoteSensingIndexes'
import constants from 'design-system/src/styles/constants'
import { commonChartConfiguration } from '@/shared/presentation/configurations/CommonChart.configuration'
import type { DetailedProject } from '@/project/domain/entities/DetailedProject'

export default class CanISeeMyRemoteSensingSpectralSpeciesPresenter {
  public constructor(private readonly projectRepository: DetailedProjectRepository) {}

  public handle(): ViewModelContainer<SpectralSpeciesViewModel> {
    const project = this.projectRepository.getProject()

    if (project.remoteSensing === null) {
      return ViewModelContainer.WithError(new NoIndexesAvailable())
    }

    return ViewModelContainer.WithContent(<SpectralSpeciesViewModel>{
      spectralSpecies: this.sortSpectralSpecies(project.remoteSensing.spectralSpecies),
      years: this.getListOfYears(project.remoteSensing.spectralSpecies),
      chart: {
        dataset: this.getChartDataset(project.remoteSensing.spectralSpecies),
        options: this.getChartOptions(project),
      },
    })
  }

  private sortSpectralSpecies(spectralSpeciesList: SpectralSpeciesProperties[]) {
    return spectralSpeciesList.sort((a, b) => (a.year > b.year ? 1 : -1))
  }

  private getListOfYears(spectralSpecies: SpectralSpeciesProperties[]): string[] {
    const years: string[] = []
    spectralSpecies.forEach((spectralSpecie: SpectralSpeciesProperties) => {
      if (spectralSpecie.year != null) {
        years.push(spectralSpecie.year)
      }
    })

    return years.sort((a, b) => (a > b ? 1 : -1))
  }

  private getChartDataset(spectralSpecies: SpectralSpeciesProperties[]) {
    return {
      labels: this.getListOfYears(spectralSpecies),
      datasets: [
        {
          type: 'line',
          data: spectralSpecies.map((ss) => ss.optimalClasses),
          borderColor: constants.colors.greenLight,
        },
      ],
    }
  }

  private getChartOptions(project: DetailedProject) {
    return {
      responsive: commonChartConfiguration.responsive,
      animation: commonChartConfiguration.animation,
      scales: commonChartConfiguration.scales('', [0, project.species.length + 1], false, ''),
      plugins: {
        ...commonChartConfiguration.plugins,
      },
    }
  }
}

class NoIndexesAvailable extends Error {
  public constructor() {
    super('No data')
  }
}
